import React, { useState } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import './TokenInfoPage.css'; // Import the new stylesheet

import PalletIcon from './icons/Pallet.png';
import DiscordIcon from './icons/Discord.png';
import TwitterIcon from './icons/Twitter.png';
import MediumIcon from './icons/Medium.png';

const TokenInfoPage = () => {
  const [tokenId, setTokenId] = useState('');
  const [tokenInfo, setTokenInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const lcdURL = 'https://sei-api.polkachu.com';
  const contractAddress = "sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl";

  const fetchTokenInfo = async () => {
    if (!tokenId) return;

    if (!/^\d+$/.test(tokenId) || parseInt(tokenId) < 1 || parseInt(tokenId) > 420) {
      setErrorMessage('Please enter a valid Token ID between 1 and 420.');
      return;
    }

    setErrorMessage(''); // Clear any previous error message

    const queryMsg = {
      "all_nft_info": {
        "token_id": tokenId
      }
    };

    const queryB64Encoded = Buffer.from(JSON.stringify(queryMsg)).toString('base64');
    const res = await axios.get(`${lcdURL}/cosmwasm/wasm/v1/contract/${contractAddress}/smart/${queryB64Encoded}`);

    if (res.data) {
      const { access, info } = res.data.data;

      // Fetch token URI information
      const tokenUriRes = await axios.get(info.token_uri);
      const tokenUriData = tokenUriRes.data;

      setTokenInfo({
        owner: access.owner,
        image: tokenUriData.image,
        attributes: tokenUriData.attributes,
        tokenUri: info.token_uri
      });
    }
  };

  return (
    <div className="token-info-page-container">
      <div className="token-info-page-back-link">
        <Link to="/" className="token-info-page-btn-link">Back to Main Page</Link>
      </div>
      <h2 className="token-info-page-title">NFT Information Lookup</h2>
      <div className="token-info-page-input-container">
        <input
          type="text"
          placeholder="Enter Token ID"
          value={tokenId}
          onChange={(e) => setTokenId(e.target.value)}
          style={{ width: '200px' }} // Inline style for width
          className="token-info-page-input"
        />
        <button onClick={fetchTokenInfo} className="token-info-page-btn-link">Get Token Info</button>
      </div>
      {errorMessage && <p className="token-info-page-error-message">{errorMessage}</p>}

      {tokenInfo && (
        <div className="token-info-page-info-container">
          <h3 className="token-info-page-owner">
            Token Owner: 
            <a href={`https://pallet.exchange/profile/${tokenInfo.owner}`} target="_blank" rel="noopener noreferrer" className="token-info-page-owner-link">
              {tokenInfo.owner}
            </a>
          </h3>
          {tokenInfo.owner === "sei152u2u0lqc27428cuf8dx48k8saua74m6nql5kgvsu4rfeqm547rsnhy4y9" && (
            <p className="token-info-page-note">
              <em>This is the Pallet contract address.</em>
            </p>
          )}
          <img src={tokenInfo.image} alt="NFT" className="token-info-page-image" />
          <h4 className="token-info-page-traits-title">Traits:</h4>
          <div className="token-info-page-traits-container">
            {tokenInfo.attributes.map((attr, index) => (
              <div key={index} className="token-info-page-trait-item">
                <span className="token-info-page-trait-type">{attr.trait_type}:</span> {attr.value}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Footer Component */}
      <footer className="token-info-page-footer">
        <div className="token-info-page-links-container">
          <p>
            <a href="https://pallet.exchange/collection/crafty-canines" target="_blank" rel="noopener noreferrer" className="token-info-page-btn-link-footer">
              <img src={PalletIcon} alt="Pallet" className="token-info-page-social-icon" />
              <span>Buy Now on Pallet</span>
            </a>
          </p>
          <p>
            <a href="https://discord.com/invite/NRmRcmJV6w" target="_blank" rel="noopener noreferrer" className="token-info-page-btn-link-footer">
              <img src={DiscordIcon} alt="Discord" className="token-info-page-social-icon" />
              <span>Join our Discord</span>
            </a>
          </p>
          <p>
            <a href="https://x.com/crafty_canines" target="_blank" rel="noopener noreferrer" className="token-info-page-btn-link-footer">
              <img src={TwitterIcon} alt="Twitter" className="token-info-page-social-icon" />
              <span>Follow us on Twitter/X</span>
            </a>
          </p>
          <p>
            <a href="https://medium.com/@craftycanines" target="_blank" rel="noopener noreferrer" className="token-info-page-btn-link-footer">
              <img src={MediumIcon} alt="Medium" className="token-info-page-social-icon" />
              <span>Follow us on Medium</span>
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default TokenInfoPage;
