import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { getWalletBalances, fetchDonationData, getCurrentSeiPrice } from './apiCalls';
import './grants.css';

function WalletBalance() {
    const [wallets, setWallets] = useState([]);
    const [filteredWallets, setFilteredWallets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [seiPrice, setSeiPrice] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'totalDonated', direction: 'descending' });
    const [searchTerm, setSearchTerm] = useState('');

    // Function to format wallet addresses
    const formatWalletAddress = (address) => {
        if (!address) return '';
        const shortAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
        return (
            <a
                href={`https://seitrace.com/address/${address}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1e90ff', textDecoration: 'none' }}
            >
                {shortAddress}
            </a>
        );
    };

    useEffect(() => {
        const loadCSVAndFetchData = async () => {
            try {
                const seiPrice = await getCurrentSeiPrice();
                if (seiPrice) {
                    setSeiPrice(seiPrice);
                } else {
                    console.error("Failed to fetch SEI price.");
                }

                const response = await fetch('/Projects.csv');
                const reader = response.body.getReader();
                const result = await reader.read();
                const decoder = new TextDecoder('utf-8');
                const csv = decoder.decode(result.value);

                Papa.parse(csv, {
                    header: true,
                    complete: async (results) => {
                        const donationData = await fetchDonationData();
                        const walletAddresses = results.data.map(row => row.Name);
                        const balances = await getWalletBalances(walletAddresses);

                        const formattedData = results.data.map(row => {
                            const donation = Array.isArray(donationData) ? donationData.find(d => d.recipient === row.Name) : null;
                            const balance = parseFloat(balances[row.Name] || '0.000000');
                            const totalDonated = parseFloat(donation ? donation.totalAmountDonatedInUsd : '0.000000');
                            const totalDonationsCount = donation ? donation.totalDonationsCount : '0';
                            const usdValue = seiPrice ? parseFloat((balance * seiPrice).toFixed(2)) : 0; // Store as number

                            return {
                                name: row.Recipient,
                                wallet: row.Name,
                                balance: balance,
                                totalDonated: totalDonated,
                                totalDonationsCount: totalDonationsCount,
                                usdValue: usdValue // Store as number
                            };
                        });

                        const rankedData = formattedData
                            .map((item, index) => ({ ...item, rank: index + 1 }))
                            .sort((a, b) => b.totalDonated - a.totalDonated)
                            .map((item, index) => ({ ...item, rank: index + 1 }));

                        setWallets(rankedData);
                        setFilteredWallets(rankedData);
                        setLoading(false);
                    },
                });
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };

        loadCSVAndFetchData();
    }, []);

    useEffect(() => {
        if (wallets.length > 0) {
            const sortedWallets = [...wallets].sort((a, b) => {
                const order = sortConfig.direction === 'ascending' ? 1 : -1;
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return (aValue - bValue) * order;
                }

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue) * order;
                }

                return 0;
            });

            setFilteredWallets(sortedWallets);
        }
    }, [wallets, sortConfig]);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = wallets.filter(wallet => wallet.name.toLowerCase().includes(value));
        setFilteredWallets(filtered);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Function to export the data as CSV
const exportToCSV = () => {
    const csvData = filteredWallets.map(wallet => ({
        Rank: wallet.rank,
        "Project Name": wallet.name,
        "Wallet Address": wallet.wallet,
        "Current SEI Balance": wallet.balance.toFixed(6),
        "Current Wallet USD Value": `$${wallet.usdValue.toFixed(2)}`,
        "Total Donated (USD)": `$${wallet.totalDonated.toFixed(2)}`,
        "Total Donations Count": wallet.totalDonationsCount,
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sei_grantee_balances.csv';
    link.click();
};


    return (
        <div className="App">
            <a
                href="https://craftycanines.xyz/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    display: 'block',
                    marginBottom: '20px',
                    fontSize: '14px',
                    color: '#1e90ff',
                    textDecoration: 'none',
                }}
            >
                Back to Crafty Canine Main Page
            </a>
            <h1>SEI Grantee Balance Checker</h1>
            <div style={{ position: 'relative', top: '-15px' }}>
                <p style={{
                    fontSize: '12px',
                    color: '#1e90ff',
                    textDecoration: 'none',
                    margin: '0',
                }}>
                    Thank You{' '}
                    <a 
                        href="https://massdrop.app" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{
                            color: '#1e90ff',
                            textDecoration: 'underline'
                        }}
                    >
                        decoderdev
                    </a>
                    &nbsp;For Help With Grant Info
                </p>
            </div>

            <input
                type="text"
                placeholder="Search by project name"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ marginBottom: '20px', padding: '8px', width: '80%' }}
            />
            
            {/* Export Button */}
			<div>
            <button onClick={exportToCSV} style={{ marginBottom: '20px', padding: '8px 16px' }}>
                Export to CSV
            </button>
</div>
            <div className="table-scroll-container">
                <table className="results-table">
                    <thead>
                        <tr>
                            <th className="rank-column" onClick={() => requestSort('rank')}>
                                <div className="header-content">
                                    <span className="header-text">Rank</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
                                        <span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                            <th onClick={() => requestSort('name')}>
                                <div className="header-content">
                                    <span className="header-text">Project Name</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
                                        <span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                            <th onClick={() => requestSort('wallet')}>
                                <div className="header-content">
                                    <span className="header-text">Wallet Address</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
                                        <span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                            <th onClick={() => requestSort('balance')}>
                                <div className="header-content">
                                    <span className="header-text">Current SEI Balance</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
										<span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                            <th onClick={() => requestSort('usdValue')}>
                                <div className="header-content">
                                    <span className="header-text">Current Wallet USD Value</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
                                        <span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                            <th onClick={() => requestSort('totalDonated')} title="Total Based On USD Value At Time Of Donation">
                                <div className="header-content">
                                    <span className="header-text">Total Donated (USD)</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
                                        <span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                            <th onClick={() => requestSort('totalDonationsCount')}>
                                <div className="header-content">
                                    <span className="header-text">Total Donations Count</span>
                                    <span className="sort-arrows">
                                        <span className="sort-arrow-up">🔼</span>
                                        <span className="sort-arrow-down">🔽</span>
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredWallets.map((wallet) => (
                            <tr key={wallet.wallet}>
                                <td>{wallet.rank}</td>
                                <td>{wallet.name}</td>
                                <td>{formatWalletAddress(wallet.wallet)}</td>
                                <td>{wallet.balance.toFixed(6)}</td>
                                <td>{`$${wallet.usdValue.toFixed(2)}`}</td> {/* New USD value column with dollar sign */}
                                <td>${wallet.totalDonated.toFixed(2)}</td>
                                <td>{wallet.totalDonationsCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default WalletBalance;
