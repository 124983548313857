import axios from 'axios';
import { formatUnits } from 'ethers';

// Function to fetch wallet balances
export async function getWalletBalances(walletAddresses) {
    const rpcUrl = 'https://evm-rpc.sei-apis.com';
    const apiKey = process.env.CRAFTYCODE;

    const batchData = walletAddresses.map((address, index) => ({
        jsonrpc: "2.0",
        method: "eth_getBalance",
        params: [
            address,
            "latest"
        ],
        id: index + 1
    }));

    try {
        const response = await axios.post(rpcUrl, batchData, {
            headers: {
                'Content-Type': 'application/json',
                'x-apikey': apiKey,
            }
        });

        const balances = response.data.reduce((acc, res) => {
            const balanceInWei = res.result;
            const balanceInEther = parseFloat(formatUnits(balanceInWei, 18));
            acc[walletAddresses[res.id - 1]] = balanceInEther.toFixed(6);
            return acc;
        }, {});

        return balances;

    } catch (error) {
        console.error("Error fetching balances:", error);
        return walletAddresses.reduce((acc, address) => {
            acc[address] = '0.000000';
            return acc;
        }, {});
    }
}

// Function to fetch donation data
export async function fetchDonationData() {
    try {
        const queryFetchIds = `
            query Applications($chainId: Int!, $roundId: String!) {
                applications(
                    condition: {
                        status: APPROVED
                        chainId: $chainId
                        roundId: $roundId
                    }
                ) {
                    id
                }
            }
        `;

        const queryFetchDetails = `
            query Applications(
                $chainId: Int!
                $applicationIds: [String!]!
                $roundId: String!
            ) {
                applications(
                    first: 1000
                    condition: {
                        status: APPROVED
                        chainId: $chainId
                        roundId: $roundId
                    }
                    filter: {
                        id: {
                            in: $applicationIds
                        }
                    }
                ) {
                    id
                    totalAmountDonatedInUsd
                    uniqueDonorsCount
                    totalDonationsCount
                    metadata
                    project: canonicalProject {
                        id
                        name
                        anchorAddress
                    }
                }
            }
        `;

        const variablesFetchIds = {
            chainId: 1329,
            roundId: "9",
        };

        const responseFetchIds = await axios.post("https://grants-stack-indexer-v2.gitcoin.co/graphql", {
            query: queryFetchIds,
            variables: variablesFetchIds,
        });

        const applicationIds = responseFetchIds.data.data.applications.map((app) => app.id);

        const variablesFetchDetails = {
            chainId: 1329,
            applicationIds: applicationIds,
            roundId: "9",
        };

        const responseFetchDetails = await axios.post("https://grants-stack-indexer-v2.gitcoin.co/graphql", {
            query: queryFetchDetails,
            variables: variablesFetchDetails,
        });

        return responseFetchDetails.data.data.applications.map((app) => ({
            recipient: app.metadata.application.recipient,
            totalAmountDonatedInUsd: app.totalAmountDonatedInUsd,
            totalDonationsCount: app.totalDonationsCount // Added field
        }));
    } catch (error) {
        console.error("Error fetching donation data:", error);
        return [];
    }
}

// Function to fetch the current SEI price
export async function getCurrentSeiPrice() {
    try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=sei-network&vs_currencies=usd');
        const seiPrice = parseFloat(response.data['sei-network'].usd);
        console.log(`Sei price fetched: $${seiPrice}`);
        return seiPrice;
    } catch (error) {
        console.error('Error fetching SEI price:', error);
        return null;
    }
}
