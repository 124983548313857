import React, { useState, useRef } from 'react';
import './App.css';
import DiscordIcon from './icons/Discord.png';
import TwitterIcon from './icons/Twitter.png';
import PalletIcon from './icons/Pallet.png';
import MediumIcon from './icons/Medium.png';
import Logo from './icons/Logo.png';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import TokenInfoPage from './TokenInfoPage';
import WalletBalance from './grants/WalletBalance';

function App() {
  const [showOnChainInfo, setShowOnChainInfo] = useState(false);
  const onChainInfoRef = useRef(null);

  const toggleOnChainInfo = () => {
    setShowOnChainInfo(!showOnChainInfo);
    if (!showOnChainInfo) {
      setTimeout(() => {
        onChainInfoRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <header className="App-header">
                <img src={Logo} alt="Crafty Canines Logo" className="logo" />
                <h1>Welcome to Crafty Canines</h1>
                <p className="description">
                  As the first NFT collection on the EVM side of SEI, our mission is simple.
                  <br />
                  Grow the SEI userbase by providing a superior onboarding experience.
                  <br />
                  Sit, stay and let us build.
                </p>
                <div className="links-container">
                  <p>
                    <a href="https://pallet.exchange/collection/crafty-canines" target="_blank" rel="noopener noreferrer" className="btn-link">
                      <img src={PalletIcon} alt="Pallet" className="social-icon" />
                      Buy Now on Pallet
                    </a>
                  </p>
                  <p>
                    <a href="https://discord.com/invite/NRmRcmJV6w" target="_blank" rel="noopener noreferrer" className="btn-link">
                      <img src={DiscordIcon} alt="Discord" className="social-icon" />
                      Join our Discord
                    </a>
                  </p>
                  <p>
                    <a href="https://x.com/crafty_canines" target="_blank" rel="noopener noreferrer" className="btn-link">
                      <img src={TwitterIcon} alt="Twitter" className="social-icon" />
                      Follow us on Twitter/X
                    </a>
                  </p>
                  <p>
                    <a href="https://medium.com/@craftycanines" target="_blank" rel="noopener noreferrer" className="btn-link">
                      <img src={MediumIcon} alt="Medium" className="social-icon" />
                      Follow us on Medium
                    </a>
                  </p>
                </div>
                <div className="tools-container">
                  <h2>Tools</h2>
                  <p>
                    <a href="https://seiguide.xyz/" target="_blank" rel="noopener noreferrer" className="btn-link">
                      SEI Guides
                    </a>
                  </p>
                  <p>
                    <a href="https://sniff.craftycanines.xyz/" target="_blank" rel="noopener noreferrer" className="btn-link">
                      SEI Wallet Sniffer
                    </a>
                  </p>
                  <p>
                    <Link to="/token-info" className="btn-link">
                      Token Info Lookup
                    </Link>
                  </p>
                  <p>
                    <Link to="/granteeinfo" className="btn-link">
                      Grantee Balance Checker
                    </Link>
                  </p>
                </div>
                <div className="games-container">
                  <h2>Fun Stuff</h2>
                  <p>
                    <a href="https://snake.craftycanines.xyz/" target="_blank" rel="noopener noreferrer" className="btn-link">
                      Play Snake Game
                    </a>
                  </p>
                  <p>
                    <a href="https://craft.craftycanines.xyz/" target="_blank" rel="noopener noreferrer" className="btn-link">
                      Crafty Canine Art Crafter
                    </a>
                  </p>
                </div>
                <div className="info-toggle-container">
                  <h2 className="info-toggle" onClick={toggleOnChainInfo}>
                    On-Chain Info
                    <span className={showOnChainInfo ? 'arrow-up' : 'arrow-down'}>{showOnChainInfo ? '▲' : '▼'}</span>
                  </h2>
                  {showOnChainInfo && (
                    <div className="info-container" ref={onChainInfoRef}>
                      <div className="info-item">
                        <strong>Collection Address:</strong>
                        <div className="address-container">
                          <a href="https://seitrace.com/token/0xbCA0f3C93cD60c09274808BAddBcf6BDBeb139c0?chain=pacific-1" target="_blank" rel="noopener noreferrer" className="address-link">
                            0xbCA0f3C93cD60c09274808BAddBcf6BDBeb139c0
                          </a>
                        </div>
                      </div>
                      <div className="info-item">
                        <strong>SEI Pointer:</strong>
                        <div className="address-container">
                          <a href="https://seitrace.com/address/sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl?chain=pacific-1" target="_blank" rel="noopener noreferrer" className="address-link">
                            sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl
                          </a>
                        </div>
                      </div>
                      <div className="info-item">
                        <strong>Royalty Wallet:</strong>
                        <div className="address-container">
                          <a href="https://www.seiscan.app/pacific-1/accounts/sei1anrnyndydyr3ymhscdztajsltcugu5307yddpu" target="_blank" rel="noopener noreferrer" className="address-link">
                            sei1anrnyndydyr3ymhscdztajsltcugu5307yddpu
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </header>
            }
          />
          <Route path="/token-info" element={<TokenInfoPage />} />
          <Route path="/granteeinfo" element={<WalletBalance />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
